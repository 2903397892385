<template>
  <div>
    <main class="container">
      <link-component></link-component>

      <div class="row g-5">
        <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              Quem Somos?
            </li>
          </ol>
        </nav>

        <div class="col-md-4 text-center">
          <img
             :src="urlImg" 
             :key="keyimgLogo"         
            class="img-fluid"
            style="max-width: 70%"
            :title="aboutItem.sub_title"
            :alt="aboutItem.sub_title"
          />

          <div class="p-4 mb-3 mt-3 bg-light rounded">
            <h4 class="fst-italic text-center">Acompanhe Nossas Redes.</h4>
            <div class="row">
              <social-component></social-component>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <article class="blog-post">
            <h2 class="blog-post-title text-center">{{ aboutItem.title }}</h2>
            <p class="blog-post-meta text-center">
              {{ aboutItem.sub_title }}
            </p>

            <span v-html="aboutItem.conteudo"></span>
            <br />
            <br />
            <b>Onde estamos:</b>
            {{ aboutItem.endereco }} <br/> CNPJ: {{ aboutItem.cnpj }}
          </article>

          <div class="p-3 bg-light rounded text-muted">
            <i class="bi bi-info-circle"></i> Ficou alguma dúvida sobre o
            estudo, artigo ou pesquisa?
            <a href="#" data-bs-toggle="modal" data-bs-target="#contato"
              >Fale Conosco</a
            >!
          </div>
        </div>
      </div>

      <div class="mt-4 mb-4">
        <apoio-component></apoio-component>
      </div>
    </main>    
  </div>
</template>

<script>
import SobreService from "../../services/sobre.service";
import { URL_BASE } from "../../services/Commons";
import apoioComponent from "../../components/apoioComponent.vue";
import linkComponent from "../../components/linkComponent.vue";
import socialComponent from "../../components/socialComponent.vue";
import { event } from 'vue-gtag';

export default {
  name: "Sobre",
  data: () => ({
    aboutItem: {},
    urlBase: "",
    urlImg:"",
    keyimgLogo: 1,
  }),
  components: {
    apoioComponent,
    linkComponent,
    socialComponent,
  },
  mounted() {
    this.urlBase = URL_BASE;    
    event('entrou_sobre', { method: 'Google' })
    SobreService.getOne().then((result) => {
      if (result.status < 400) {
        this.aboutItem = result.data;
        this.urlImg = URL_BASE + `sobredowimagem/${this.aboutItem.id}/`;        
        this.keyimgLogo ++;
      }
    });
  },
};
</script>
